<template>
  <div class="open-api">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-a-shezhi2"></use>
      </svg>
    </breadcrumbs>
    <a-spin :spinning="spinning">
      <div class="content">
        <div class="creat-api">
          <p>Create ArtemisAds Open API Key</p>
          <div class="default-cont">
            <div v-if="apiKey">API_Key: <span @click="copyKey"> {{apiKey}}</span></div>
            <artmisads-button v-else-if="apiKey === ''" @click="makeOpenApiKey" type="primary">Generate</artmisads-button>
          </div>
        </div>
        <div class="bottom-cont">
          <span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-warring-tianchong3"></use>
            </svg>
          </span>
          <div class="text">
            <span>Steps: Generate API Key > Development & Testing > Online API Call</span>
            <span>View our developer docs for more info: <a target="_blank" href="https://docs.google.com/document/d/1PGJIC8Y9lLFngkGRjV10INEU14zEhJzMr0Iriv07qNM/edit?tab=t.0">https://docs.google.com/document/d/1PGJIC8Y9lLFngkGRjV10INEU14zEhJzMr0Iriv07qNM/edit?tab=t.0</a></span>
          </div>
        </div>
      </div>
    </a-spin>
    
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';

import { signApi } from '~/server/index'

import breadcrumbs from '../../../seller/breadcrumbs.vue';

import SvgIcon from '~/components/SvgIcon.vue';
import { message } from 'ant-design-vue';

const spinning = ref(false);
const apiKey = ref(null)

const breadcrumbsData = {
title: 'Open API',
color:'#FFD358',
links: 
  [
    {
      name: 'Home',
      path: '/publisher/settings/open-api'
    },
    {
      name: 'Settings',
      path: '/publisher/settings/open-api',
    },
    {
      name: 'Open API',
      path: '/publisher/settings/open-api'
    }
  ]
};

const copyKey = async copyText => {
  await navigator.clipboard.writeText(apiKey.value);
  message.success('Copied!',1)
}

const getOpenApiKey =  async() => {
  spinning.value = true;
  const res = await signApi.getOpenApiKey();
  const { success, data } = res;
  if (success) {
    apiKey.value = data.apiKey || '';
  } else {
    apiKey.value = '';
  }
  spinning.value = false;
}

const makeOpenApiKey =  async() => {
  spinning.value = true;
  const res = await signApi.makeOpenApiKey();
  const { success, data } = res;
  if (success) {
    apiKey.value = data.apiKey || '';
    if (data.apiKey) {
      message.success('Open API Key Generated');
    }
  }
  spinning.value = false;
}

onBeforeMount(() => {
  getOpenApiKey();
})

</script>

<style scoped lang="less">
  .open-api {
    .content {
      padding: 16px;
      border-radius: 12px;
      overflow: hidden;
      border-radius: 12px;
      background-color: var(--color-white);
      box-shadow: var(--section-box-shadow-6);
    }
    .creat-api {
      border-bottom: 1px solid var(--border-4);
      padding-bottom: 30px;
      > p {
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .default-cont {
        height: 40px;
        > div {
          font-weight: 400;
          font-size: 14px;
          line-height: 40px;
          letter-spacing: 0.5px;
          > span {
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
    .bottom-cont {
      position: relative;
      display: flex;
      padding-top: 16px;
      > span {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        box-shadow: 0 0 0 3px var(--yellow-icon-bg);
        justify-content: center;
        margin-right: 8px;
        svg {
          width: 22px;
          min-width: 22px;
          height: 22px;
          fill: var(--yellow-7);
          align-self: center;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        > span {
          font-weight: 400;
          font-size: 14px;
          color: var(--dark-1);
          line-height: 19px;
          &:first-child {
            margin-bottom: 8px;
          }
          > a {
            text-decoration: underline;
            color: var(--dark-1);
            line-height: 19px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
</style>